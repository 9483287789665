
  import { Component, Vue } from 'vue-property-decorator';

  import HipsterInput from '@/components/HipsterInput.vue';
  import HipsterSelector from '@/components/HipsterSelector.vue';
  import HipsterCheckbox from '@/components/HipsterCheckbox.vue';
  import InspiniaGridItem from '@/components/InspiniaGridItem.vue';

  import { SearchCompetitionsParams } from '@/types/search';
  import { SimpleTableRowItem, SimpleTableHeaderItem } from '@/types/search';
  import { DELETE_COMPETITION, FETCH_COMPETITIONS } from '@/store/competitions';
  import { Competition } from '@/types/entities';
  import SearchResultsTable from '@/components/SearchResultsTable.vue';

  @Component({
    components: { SearchResultsTable, HipsterCheckbox, HipsterInput, HipsterSelector, InspiniaGridItem },
    computed: {
      competitions() {
        return this.$store.state.competitions.competitionsList.data
          .map((competition: Competition): SimpleTableRowItem => {
            return {
              to: `/competitions/${competition.id}`,
              line: [
                competition.id as any as string,
                competition.name,
                competition.type,
                competition.from as any as string,
                competition.to as any as string,
                competition.deleted_at ? 'No' : 'Yes'
              ]
            };
          });
      }
    }
  })
  export default class InsightsList extends Vue {
    public tableHeader: SimpleTableHeaderItem[] = [
      { class: 'col-lg-1', name: 'id' },
      { class: 'col-lg-3', name: 'name' },
      { class: 'col-lg-2', name: 'type' },
      { class: 'col-lg-2', name: 'from' },
      { class: 'col-lg-2', name: 'to' },
      { class: 'col-lg-1', name: 'Active' }
    ];

    public filters: SearchCompetitionsParams = {
      query: '',
      with_trashed: 1,
      per_page: 12,
      page: 1
    };

    created() {
      this.search({});
    }

    public async deleteCompetition(competitionId: number) {
      await this.$store.dispatch(DELETE_COMPETITION, { competitionId, searchParams: this.filters });
    }

    public search(filter: SearchCompetitionsParams) {
      if (!filter.page) {
        filter.page = 1;
      }

      this.$store.dispatch(FETCH_COMPETITIONS, Object.assign(this.filters, filter));
    }
  }
